<template>
  <section>
    <!--工具条-->
    <el-form :inline="true" class="toolbar" :model="searchForm">
      <el-form-item label="渠道">
        <el-select clearable size="mini" v-model="searchForm.accountId">
          <el-option
            v-for="item in accountIdList"
            :key="item.id"
            :label="item.name"
            :value="item.name"
          />
        </el-select>
      </el-form-item>
      <el-form-item label="收款方姓名">
        <el-input
          v-model="searchForm.username"
          size="mini"
          clearable
        ></el-input>
      </el-form-item>
      <!-- <el-form-item label="收款方邮箱">
        <el-input v-model="searchForm.email" size="mini" clearable></el-input>
      </el-form-item> -->
      <!-- <el-form-item label="收款方手机号">
        <el-input
          v-model="searchForm.cellphone"
          size="mini"
          clearable
        ></el-input> </el-form-item
      > -->
      <el-form-item label="IFSC">
        <el-input v-model="searchForm.ifsc" size="mini" clearable></el-input>
      </el-form-item>
      <el-form-item label="用户卡号">
        <el-input v-model="searchForm.cardNo" size="mini" clearable></el-input>
      </el-form-item>
      <el-form-item label="审核状态">
        <el-select clearable size="mini" v-model="searchForm.status">
          <el-option
            v-for="item in statusList"
            :key="item.code"
            :label="item.name"
            :value="item.code"
          />
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-button @click="search" type="primary" size="mini">查询</el-button>
      </el-form-item>
      <!-- <el-form-item>
        <el-button @click="add" type="primary" size="mini">新增</el-button>
      </el-form-item> -->
      <el-form-item>
        <el-button @click="batchAudit" type="primary" size="mini"
          >批量审核</el-button
        >
      </el-form-item>
    </el-form>
    <el-table
      :data="tableData"
      border
      height="650"
      style="width: 100%"
      v-loading="loading"
      @selection-change="handleSelectionChange"
    >
      <el-table-column type="selection" width="55"></el-table-column>
      <!-- <el-table-column prop="merchantName" label="付款商户"></el-table-column> -->
      <el-table-column prop="channel" label="交易渠道"></el-table-column>

      <el-table-column prop="amount" label="金额">
        <template slot-scope="scope">
          <span>{{ scope.row.amount / 100 }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="username" label="收款方姓名"></el-table-column>
      <!-- <el-table-column prop="email" label=" 收款方邮箱"></el-table-column>
      <el-table-column
        prop="cellphone"
        label="收款方手机号码"
      ></el-table-column>
      <el-table-column prop="purpose" label="交易用途"></el-table-column> -->
      <el-table-column prop="ifsc" label="IFSC"></el-table-column>
      <el-table-column prop="cardNo" label="用户卡号"></el-table-column>
      <el-table-column prop="utr" label="utr"></el-table-column>
      <el-table-column prop="status" label="状态">
        <template slot-scope="scope">
          <span>{{ statusDesc(scope.row.status) }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="withdrawStatus" label="兑付状态">
        <template slot-scope="scope">
          <span>{{ cashStatusDesc(scope.row.withdrawStatus) }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="remark" label="备注"></el-table-column>
      <el-table-column prop="tradeNo" label="支付单号"></el-table-column>
      <el-table-column prop="transactionId" label="三方单号"></el-table-column>
      <el-table-column label="操作" fixed="right" width="100">
        <template slot-scope="scope">
          <!-- <el-button
            type="primary"
            size="mini"
            @click="edit(scope.row)"
            v-if="scope.row.status != 1"
            >编辑</el-button
          > -->
          <el-button
            type="primary"
            v-if="scope.row.status == 0"
            size="mini"
            @click="audit(scope.row)"
            >审核</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      class="pager"
      @size-change="pageSizeChange"
      @current-change="pageIndexChange"
      :current-page="pageIndex"
      :page-size="pageSize"
      layout="total, sizes, prev, pager, next, jumper"
      :total="dataTotal"
    ></el-pagination>
    <el-dialog
      :title="dialogTitle"
      width="50%"
      :close-on-click-modal="false"
      :visible.sync="addOrEditDialogForm"
    >
      <el-form :model="form" :rules="rules" ref="form" label-width="120px">
        <el-form-item label="交易渠道" prop="accountId">
          <el-col :span="12">
            <el-select clearable v-model="form.accountId">
              <el-option
                v-for="item in accountIdList"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              />
            </el-select>
          </el-col>
        </el-form-item>
        <el-form-item label="金额" prop="amount">
          <el-input v-model="form.amount" clearable></el-input>
        </el-form-item>
        <el-form-item label="收款方姓名" prop="username">
          <el-input v-model="form.username" clearable></el-input>
        </el-form-item>
        <!-- <el-form-item label="收款方邮箱" prop="email">
          <el-input v-model="form.email" clearable></el-input>
        </el-form-item>
        <el-form-item label="收款方手机号" prop="cellphone">
          <el-input v-model="form.cellphone" clearable></el-input>
        </el-form-item>
        <el-form-item label="交易用途" prop="purpose">
          <el-input v-model="form.purpose" clearable></el-input>
        </el-form-item> -->
        <el-form-item label="IFSC" prop="ifsc">
          <el-input v-model="form.ifsc" clearable></el-input>
        </el-form-item>
        <el-form-item label="用户卡号" prop="cardNo">
          <el-input v-model="form.cardNo" clearable></el-input>
        </el-form-item>
      </el-form>

      <div slot="footer" class="dialog-footer">
        <el-button @click="addOrEditDialogForm = false">取消</el-button>
        <el-button type="primary" @click="submit" :loading="formLoading"
          >确定</el-button
        >
      </div>
    </el-dialog>
    <el-dialog
      title="审核"
      width="50%"
      :close-on-click-modal="false"
      :visible.sync="auditDialogVisible"
    >
      <el-form
        :model="auditForm"
        :rules="auditRules"
        ref="auditForm"
        label-width="120px"
      >
        <el-form-item label="审核状态" prop="status">
          <el-col :span="12">
            <el-select clearable v-model="auditForm.status">
              <el-option
                v-for="item in statusList"
                :key="item.code"
                :label="item.name"
                :value="item.code"
              />
            </el-select>
          </el-col>
        </el-form-item>
        <el-form-item label="备注">
          <el-col :span="12">
            <el-input
              type="textarea"
              :rows="2"
              placeholder="请输入内容"
              v-model="auditForm.remark"
            >
            </el-input>
          </el-col>
        </el-form-item>
      </el-form>

      <div slot="footer" class="dialog-footer">
        <el-button @click="auditDialogVisible = false">取消</el-button>
        <el-button
          type="primary"
          @click="auditSubmit"
          :loading="auditFormLoading"
          >确定</el-button
        >
      </div>
    </el-dialog>
  </section>
</template>

<script>
import api from "../../api/orderManage/payoutAuditOrder";
import merchantApi from "@/api/merchantPayConfig/merchantApi";
export default {
  data() {
    return {
      pageIndex: 1,
      pageSize: 10,
      dataTotal: 0,
      loading: false,
      addOrEditDialogForm: false,
      formLoading: false,
      tableData: [],
      accountIdList: [],
      statusList: [
        { code: 0, name: "待审核" },
        { code: 1, name: "通过" },
        { code: 2, name: "拒绝" },
      ],
      cashStatusList: [
        { code: 0, name: "初始化" },
        { code: 1, name: "处理中" },
        { code: 2, name: "交易成功" },
        { code: 3, name: "交易失败" },
        { code: 4, name: "回退" },
        { code: 5, name: "其他" },
      ],
      searchForm: {
        accountId: "",
        username: "",
        // email: "",
        // cellphone: "",
        ifsc: "",
        cardNo: "",
        status: "",
      },

      rules: {
        amount: [
          {
            required: true,
            message: "请填写金额",
            trigger: "blue",
          },
        ],
        username: [
          {
            required: true,
            message: "请填写收款方姓名",
            trigger: "blue",
          },
        ],
        // email: [
        //   {
        //     required: true,
        //     message: "请填写收款方邮箱",
        //     trigger: "blue",
        //   },
        // ],
        // cellphone: [
        //   {
        //     required: true,
        //     message: "请填写收款方手机号",
        //     trigger: "blue",
        //   },
        // ],
        // purpose: [
        //   {
        //     required: true,
        //     message: "请填写交易用途",
        //     trigger: "blue",
        //   },
        // ],
        ifsc: [
          {
            required: true,
            message: "请填写IFSC",
            trigger: "blue",
          },
        ],
        cardNo: [
          {
            required: true,
            message: "请填写用户卡号",
            trigger: "blue",
          },
        ],
        accountId: [
          {
            required: true,
            message: "请选择交易渠道",
            trigger: "change",
          },
        ],
      },

      form: {
        accountId: "",
        amount: "",
        username: "",
        // email: "",
        // cellphone: "",
        // purpose: "",
        ifsc: "",
        cardNo: "",
      },

      dialogTitle: "",
      multipleSelection: [],
      auditForm: {
        ids: "",
        status: "",
        remark: "",
      },
      auditRules: {
        status: [
          {
            required: true,
            message: "请选择审核状态",
            trigger: "change",
          },
        ],
      },
      auditDialogVisible: false,
      auditFormLoading: false,
    };
  },
  methods: {
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    accountIdSelect(val) {
      api
        .getAaccountList({
          merchantId: val,
        })
        .then((res) => {
          if (res.code == "0000") {
            this.accountIdList = res.data;
          }
        });
    },
    statusDesc(val) {
      let name = "";
      this.statusList.forEach((item) => {
        if (item.code == val) {
          name = item.name;
        }
      });
      return name;
    },
    cashStatusDesc(val) {
      let name = "";
      this.cashStatusList.forEach((item) => {
        if (item.code == val) {
          name = item.name;
        }
      });
      return name;
    },

    search() {
      this.pageIndex = 1;
      this.bindGrid();
    },
    add() {
      this.$refs.form && this.$refs.form.resetFields();
      for (let key in this.form) {
        this.form[key] = "";
      }
      delete this.form.id;
      delete this.form.merchantId;
      this.addOrEditDialogForm = true;
      this.dialogTitle = "新增";
    },
    audit(item) {
      this.auditForm.ids = item.id;
      this.auditForm.remark = "";
      this.auditForm.status = "";
      this.auditDialogVisible = true;
    },
    batchAudit() {
      if (this.multipleSelection.length == 0) {
        this.$message.error("请选择要审核的订单");
        return;
      }
      let ids = [];
      this.multipleSelection.forEach((item) => {
        ids.push(item.id);
      });
      this.auditForm.ids = ids.join(",");
      this.auditForm.remark = "";
      this.auditForm.status = "";
      this.auditDialogVisible = true;
    },
    auditSubmit() {
      this.auditFormLoading = true;
      let _data = {};
      let content = Object.assign({}, this.auditForm);

      _data.content = content;
      api.auditPayOutAuditOrder(_data).then((res) => {
        this.logining = false;
        if (res.code == "0000") {
          this.auditFormLoading = false;
          this.$message({
            message: "操作成功",
            type: "success",
          });
          this.auditForm.ids = "";
          this.auditForm.remark = "";
          this.auditForm.status = "";
          this.multipleSelection = [];
          this.auditDialogVisible = false;
          this.bindGrid();
        } else {
          this.auditFormLoading = false;
          this.$message.error(res.message);
        }
      });
    },
    bindGrid() {
      this.loading = true;
      let _data = {};
      let content = Object.assign({}, this.searchForm);
      content.pageNo = this.pageIndex;
      content.pageSize = this.pageSize;
      _data.content = content;
      api.getPayOutAuditOrder(_data).then((res) => {
        this.logining = false;
        if (res.code == "0000") {
          this.loading = false;
          this.tableData = res.data.rows;
          this.dataTotal = res.data.total;
        } else {
          this.$message.error(res.message);
        }
      });
    },
    edit(row) {
      for (let key in this.form) {
        this.form[key] = row[key];
      }
      this.form.id = row.id;
      this.form.merchantId = row.merchantId;
      this.dialogTitle = "修改";
      this.addOrEditDialogForm = true;
    },
    submit() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.formLoading = true;
          let _data = {};
          let content = Object.assign({}, this.form);
          content.amount = content.amount * 100;
          _data.content = content;
          if (this.dialogTitle == "新增") {
            api.addPayOutAuditOrder(_data).then((res) => {
              this.formLoading = false;
              if (res.code == "0000") {
                this.addOrEditDialogForm = false;
                this.$message({
                  message: "新增成功",
                  type: "success",
                });
                this.bindGrid();
              } else {
                this.$message.error(res.message);
              }
            });
          } else {
            api.updatePayOutAuditOrder(_data).then((res) => {
              this.formLoading = false;
              if (res.code == "0000") {
                this.addOrEditDialogForm = false;
                this.$message({
                  message: "修改成功",
                  type: "success",
                });
                this.bindGrid();
              } else {
                this.$message.error(res.message);
              }
            });
          }
        }
      });
    },

    // 分页
    pageSizeChange(val) {
      this.pageSize = val;
      this.pageIndex = 1;
      this.bindGrid();
    },
    pageIndexChange(val) {
      this.pageIndex = val;
      this.bindGrid();
    },
  },
  mounted: function () {
    this.bindGrid();
    this.accountIdSelect("");
  },
  components: {},
};
</script>

<style lang="scss">
@import "../../assets/css/base";
</style>

import request from "@/utils/http/requset";

function getPayOutAuditOrder(data) {
    return request({
        url: "/manage/payoutAudit/search",
        method: "post",
        ContentType: "application/json;charset=UTF-8",
        data: data
    });
}
function auditPayOutAuditOrder(data) {
    return request({
        url: "/manage/payoutAudit/audit",
        method: "post",
        ContentType: "application/json;charset=UTF-8",
        data: data
    });
}

function addPayOutAuditOrder(data) {
    return request({
        url: "/manage/payoutAudit/create",
        method: "post",
        ContentType: "application/json;charset=UTF-8",
        data: data
    });
}
function updatePayOutAuditOrder(data) {
    return request({
        url: "/manage/payoutAudit/update",
        method: "post",
        ContentType: "application/json;charset=UTF-8",
        data: data
    });
}


function getAaccountList(data) {
    return request({
        url: "/manage/payAccount/channelsAll",
        method: "post",
        ContentType: "application/json;charset=UTF-8",
        data: data
    });
}
///manage/payoutAudit/audi
export default {
    getPayOutAuditOrder,
    auditPayOutAuditOrder,
    addPayOutAuditOrder,
    updatePayOutAuditOrder,
    getAaccountList
};
